import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ItemDetails } from "../../../../Utils/api/auth";
import AccessProductsModal from "../AccessProductsModal/AccessProductsModal";
import { AuthContext } from "../../../../App";

interface OrderProps {
  itemDetails: ItemDetails[];
  bundle: boolean;
  invoiceNo: string;
}

const Order: React.FC<OrderProps> = ({ itemDetails, bundle, invoiceNo }) => {
  const navigate = useNavigate();

  const {
    auth: { student_username, isInternational },
  } = useContext(AuthContext);

  const [name, setName] = useState(itemDetails[0].name)
  const [image, setImage] = useState(itemDetails[0].image)
  useEffect(() => {
    if (bundle) {
      setName(itemDetails.map((item) => item.name).join(", "));
      setImage(itemDetails[0].image);
    }
  }, [bundle, itemDetails]);

  const [accessProductModalOpen, setAccessProductModalOpen] = useState(false);

  const handleOpenProductsModal = () => {
    setAccessProductModalOpen(true);
  };

  const handleCloseProductsModal = () => {
    setAccessProductModalOpen(false);
  };

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  return (
    <div className="order">
      <div className="order-wrap">
        <div className="order-content">
          <img className="order-pic" src={image} alt="Order image" />
          <div className="order-title">
            <div className="order-title2">{name}</div>
          </div>
        </div>
        <div style={{ flexDirection: "column" }}>
          {((!bundle &&
            itemDetails[0].name !==
            "National Finance Olympiad & Study Material") ||
            bundle) && (
              <div className="order-frame">
                <button
                  className="order-rectangle"
                  disabled={
                    !bundle && itemDetails[0].name === "National Finance Olympiad"
                  }
                  onClick={
                    bundle
                      ? handleOpenProductsModal
                      : () => {
                        if (itemDetails[0].id === 2) {
                          const token = localStorage.getItem("accessToken");
                          if (token) {
                            openInSameTab(
                              `https://olympiad.nationalfinanceolympiad.com?token=${token}&userName=${student_username}`
                            );
                          } else {
                            console.log(
                              "accesstoken not found in localstorage"
                            );
                          }
                        } else {
                          navigate("/pdf-preview", {
                            state: {
                              type: itemDetails[0].name,
                              link: itemDetails[0].link,
                              goBack: true,
                            },
                          });
                        }
                      }

                }
              >
                <div className="order-text-wrapper-3"> Access product</div>
              </button>
            </div>
          )}
          {!isInternational && (
            <div
              className="order-text-wrapper-4"
              onClick={() => {
                navigate("/invoice-viewer", {
                  state: {
                    invoiceNo: invoiceNo,
                  },
                });
              }}
            >
              Download invoice
            </div>
          )}
        </div>
      </div>
      <AccessProductsModal
        itemDetailsArray={itemDetails}
        openModal={accessProductModalOpen}
        cbHandleCloseModal={handleCloseProductsModal}
      />
    </div>
  );
};

export default Order;
