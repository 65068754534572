import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ComplimentaryBadge,
  ProductInfoIcon,
  refreshCcw,
  shelfDepthRect,
  shelfFrontRect,
  shelfTitleRect,
} from "../../../../Assets/images";
import {
  StoreItems,
  getStoreItems,
  LogEventBody,
  logEvent,
} from "../../../../Utils/api/auth";
import { useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import { arrowLeftCircle, arrowRightCircle } from "../../../../Assets/svgs";
import PreviewModal from "./PreviewModal";

import { AuthContext } from "../../../../App";

import {
  fAnalytics,
  logFirebaseEvent,
} from "../../../../Utils/firebase/firebaseConfig";
import Lottie from "lottie-react";
import BadgeLottie1 from "../../../../Assets/lotties/BadgeLottie1.json";

const Bookshelf = () => {
  const bookshelfScrollRef = useRef<HTMLDivElement>(null);

  const handleScrollLeft = () => {
    if (bookshelfScrollRef.current) {
      bookshelfScrollRef.current.scrollLeft -= 100;
      console.log("in");
    }
    console.log("out");
  };

  const handleScrollRight = () => {
    if (bookshelfScrollRef.current) {
      bookshelfScrollRef.current.scrollLeft += 100;
      console.log("in");
    }
    console.log("out");
  };

  const [storeItems, setStoreItems] = useState([]);
  const [shelfLoading, setShelfLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const {
    auth: {
      student_username,
      name,
      phone,
      email,
      grade,
      report_generated,
      school,
      examDate,
      showMockOlympiad,
      examLink,
    },
  } = useContext(AuthContext);
  const [currentDescription, setCurrentDescription] = useState({
    basePrice: "",
    sellingPrice: "",
    description: "",
    imgSrc: "",
    onPress: () => {},
    buttonText: "",
    itemName: "",
  });

  const viewInfoItemEvent = (itemName: string) => {
    logFirebaseEvent("view_item", {
      user_id_custom: student_username,
      itemName: itemName,
      grade: grade,
      school: school,
    });
  };

  const handleFetchShelfItems = () => {
    if (storeItems.length === 0) {
      setShelfLoading(true);
    }
    getStoreItems().then((res) => {
      //@ts-ignore
      if (res && res.data && res.data.status === "Success") {
        //@ts-ignore
        setStoreItems(res.data.data.items);
      } else {
        setStoreItems([]);
      }
      setShelfLoading(false);
    });

    console.log(
      storeItems.map((e: StoreItems) => {
        return e.id;
      })
    );
  };

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  useEffect(() => {
    handleFetchShelfItems();
  }, []);

  return (
    <div className="bookshelf-full-wrapper">
      <div className="nfo-portal-v2-frame">
        <div className="nfo-portal-v2-text-wrapper-5">Bookshelf</div>
      </div>
      <div className="bookshelf-child-container">
        <button
          className="bookshelf-left-scroll"
          style={{
            top: "40%",
            left: 5,
            position: "absolute",
            border: "none",
            zIndex: 10,
            background: "none",
          }}
          onClick={handleScrollLeft}
        >
          <img src={arrowLeftCircle} alt="<-" />
        </button>
        <button
          className="bookshelf-right-scroll"
          style={{
            top: "40%",
            right: 5,
            position: "absolute",
            border: "none",
            zIndex: 10,
            background: "none",
          }}
          onClick={handleScrollRight}
        >
          <img src={arrowRightCircle} alt="->" />
        </button>
        {shelfLoading && (
          <div
            style={{
              width: "100%",
              top: "40%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!shelfLoading && storeItems.length === 0 && (
          <div className="refresh-bookshelf" onClick={handleFetchShelfItems}>
            <img src={refreshCcw} alt="()" width={"15px"} />
            <p>Refetch</p>
            <p>Nothing to show now</p>
          </div>
        )}

        <div className="nfo-portal-v2-bookshelf" ref={bookshelfScrollRef}>
          <img
            className="nfo-portal-v2-img"
            alt="Rectangle"
            src={shelfDepthRect}
          />
          <img
            className="nfo-portal-v2-vector-2"
            alt="Vector"
            src={shelfFrontRect}
          />
          <div className="nfo-portal-v2-overlap-group-wrapper">
            <div className="nfo-portal-v2-overlap-group">
              <div className="nfo-portal-v2-frame-wrapper">
                <div className="nfo-portal-v2-frame-3-for-books">
                  {storeItems.map((e: StoreItems, index) => {
                    // if (e.name !== "Mock Test")
                    if (e.id !== 2)
                      return (
                        <div className="nfo-portal-v2-book">
                          <img
                            key={e.id + index}
                            src={e.image}
                            className="nfo-portal-v2-book"
                          />

                          {e.paid ? (
                            <Lottie
                              className="lottie-badge"
                              animationData={BadgeLottie1}
                              loop={true}
                            />
                          ) : (
                            <img
                              src={ComplimentaryBadge}
                              className="complimentary-badge"
                            />
                          )}
                        </div>
                      );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="nfo-portal-v2-frame-wrapper">
            <div className="nfo-portal-v2-frame-3">
              {storeItems.map((e: StoreItems) => {
                if (e.id !== 2) {
                  console.log(e.base_price, e.selling_price);
                  return (
                    <div key={e.id} className="nfo-portal-v2-frame-4">
                      <div className="nfo-portal-v2-book-title">{e.name}</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          onClick={() => {
                            if (e.locked) {
                              logFirebaseEvent("add_to_cart", {
                                user_id_custom: student_username,
                                itemName: e.name,
                                grade: grade,
                                school: school,
                              }).then(() => {
                                openInSameTab(
                                  `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${e.id}&token=${token}&userName=${student_username}`
                                );
                              });
                            } else {
                              navigate("/pdf-preview", {
                                state: {
                                  type: e.name,
                                  link: e.link,
                                },
                              });
                            }
                          }}
                          className="nfo-portal-v2-button"
                          style={{
                            backgroundColor: e.locked ? "#2E6BB2" : "#62646726",
                          }}
                        >
                          <div
                            className="nfo-portal-v2-text-wrapper-6"
                            style={{
                              color: e.locked ? "#FFF" : "#2E6BB2",
                            }}
                          >
                            {e.locked ? "Buy" : "View"}
                          </div>
                        </div>

                        {e.locked ? (
                          <div
                            onClick={async () => {
                              logFirebaseEvent("view_item", {
                                user_id_custom: student_username,
                                itemName: e.name,

                                grade: grade,
                                school: school,
                              }).then(() => {
                                setCurrentDescription({
                                  description:
                                    "The NFO Question Paper Bank provides students with access to 12 unique question papers (Including NFO 2023 question papers), helping them deepen their understanding of each topic and effectively challenge themselves. These papers will familiarise students with the Olympiad's question format, ensuring they are well-prepared for the competition.",
                                  sellingPrice: `${e.selling_price}`,
                                  basePrice: `${e.base_price}`,
                                  imgSrc: `${e.image}`,
                                  itemName: e.name,
                                  onPress: () => {
                                    e.locked
                                      ? openInSameTab(
                                          `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${e.id}&token=${token}&userName=${student_username}`
                                        )
                                      : navigate("/pdf-preview", {
                                          state: {
                                            type: e.name,
                                            link: e.link,
                                          },
                                        });
                                  },
                                  buttonText: e.locked ? "Buy" : "View",
                                });
                                const logBody: LogEventBody = {
                                  studentUserID: student_username,
                                  eventName: "view_item_info",
                                  productIDs: [e.id],
                                };
                                logEvent(logBody);
                                setShowInfoModal(true);
                              });
                            }}
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="nfo-info-icon"
                          >
                            <img
                              src={ProductInfoIcon}
                              style={{
                                height: "19px",
                                objectFit: "contain",
                                width: "20px",
                                alignSelf: "center",
                                marginLeft: 10,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <PreviewModal
        itemName={currentDescription.itemName}
        buttonText={currentDescription.buttonText}
        onPress={currentDescription.onPress}
        imgSrc={currentDescription.imgSrc}
        openModal={showInfoModal}
        cbHandleCloseModal={() => {
          setShowInfoModal(false);
        }}
        currentDescription={currentDescription.description}
        sellingPrice={currentDescription.sellingPrice}
        basePrice={currentDescription.basePrice}
      />
    </div>
  );
};

export default Bookshelf;
