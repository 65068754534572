import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Login.css";
import { NFO, backArrowImage } from "../../Assets/images";
import { Alert, Button } from "@mui/material";
import { sendOtp, userData, verifyOtp } from "../../Utils/api/auth";
import { BootstrapInput } from "../../Components/InputField";
import { AuthContext } from "../../App";
import Cookies from "js-cookie";
import LoadingButton from "@mui/lab/LoadingButton";
import TagManager from "react-gtm-module";
import InvalidSession from "../InvalidSession/InvalidSession";
import * as Sentry from "@sentry/react";
// import { logEvent } from "firebase/analytics";
import {
  fAnalytics,
  logFirebaseEvent,
} from "../../Utils/firebase/firebaseConfig";
import { LogEventBody, logEvent } from "../../Utils/api/auth";

const Login = () => {
  const [formData, setFormData] = useState({ userId: "", otp: "" });
  const [errors, setErrors] = useState<
    { userId: string; otp: string } | undefined
  >({
    userId: "",
    otp: "",
  });
  const [didSendOtp, setDidSendOtp] = useState(false);
  const [resentOtpCount, setResentOtpCount] = useState(0);
  const [resendTimeLeft, setResendTimeLeft] = useState(30);
  const { invalidSession, setAuth, auth } = useContext(AuthContext);
  const [viewType, setViewType] = useState("login");
  const [loading, setLoading] = useState(false);

  const handleTimeOver = async (): Promise<void> => {
    console.log("Time Over Now !!");
    // to implement
  };

  const retry = useRef(0);

  useEffect(() => {
    if (invalidSession && viewType !== "invalid-session") {
      setViewType("invalid-session");
    }
  }, [invalidSession]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userName = urlParams.get("userName");
    console.log(userName);
    if (userName) {
      setFormData({ ...formData, userId: userName });
    }
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({ ...formData, [name]: value.toUpperCase() });
    if (errors) setErrors(undefined);
  };
  console.log(retry.current);

  const sendOtpWithRetries = async (userId: string) => {
    const newErrors: { userId: string; otp: string } = { userId: "", otp: "" };
    try {
      while (retry.current < 20) {
        const response = await sendOtp(userId);
        //@ts-ignore
        if (response && response.data && response.data.status == "Success") {
          // OTP sent successfully
          setResendTimeLeft(30);
          // Reset didSendOtp and then set it back to true
          setDidSendOtp(false); // Reset first
          setTimeout(() => setDidSendOtp(true), 0); // Set to true after a tiny delay

          setLoading(false); // Ensure to stop any loading indicator
          return; // Exit the loop on success
        } else {
          retry.current++;
        }
        setLoading(false);
        retry.current = 0;
        throw new Error("Maximum retry limit reached");
      }
    } catch (err) {
      setLoading(false);
      //@ts-ignore
      if (err.response.data.message === "user does not exist") {
        // message in response if non-existent user
        newErrors.userId = "Incorrect roll number. Please re-enter to log in.";
      } else if (
        //@ts-ignore
        err.response.data.message === "too many attempts to send otp"
      ) {
        newErrors.userId =
          "Too many invalid attempts! Please try again after sometime.";
      } else {
        newErrors.userId = "Something went wrong...";
      }
      console.log(newErrors.userId);
      Sentry.captureException(err);
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (didSendOtp) {
      timer = setInterval(() => {
        setResendTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 0) {
            clearInterval(timer!);
            return 0;
          } else {
            return prevTimeLeft - 1;
          }
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer); // Clear timer when component unmounts or when `didSendOtp` changes
      }
    };
  }, [didSendOtp]); // Only starts when `didSendOtp` becomes true

  const handleSubmitPhoneNumber = async (
    //@ts-ignore
    e
  ) => {
    e.preventDefault();
    const newErrors: { userId: string; otp: string } = { userId: "", otp: "" };
    if (formData.userId.length == 0) {
      newErrors.userId = "Please enter your roll number";
    }
    setErrors(newErrors);
    console.log("Handling submit phone number");
    if (Object.keys(newErrors.userId).length === 0) {
      try {
        setLoading(true);
        setResendTimeLeft(30);
        const { userId } = formData;
        sendOtpWithRetries(userId);
      } catch (err) {
        newErrors.userId = "User is not registered on the Platform.";
        console.log(newErrors.userId);
        Sentry.captureException(err);
        setErrors(newErrors);
        setLoading(false);
      }
    }
  };

  const handleSubmitOTP = async () => {
    const newErrors: { userId: string; otp: string } = { userId: "", otp: "" };

    if (formData.otp.length !== 4) {
      newErrors.otp = "OTP must be 4 digits";
      setErrors(newErrors);
      return;
    }
    setErrors(newErrors);
    if (Object.keys(newErrors.userId).length === 0) {
      setLoading(true);
      try {
        const { userId: userId, otp } = formData;
        const response = await verifyOtp({ studentUserName: userId, otp });
        //@ts-ignore
        const userData = response.data.data;
        console.log("this is school", userData.school);
        //@ts-ignore
        if (response.data.status === "Success" && userData) {
          setLoading(false);
          localStorage.setItem("x_id", userData?.student_username);
          Cookies.set("accessToken", userData?.token, {
            expires: 4,
            secure: true,
          });
          localStorage.setItem("accessToken", userData.token);
          localStorage.setItem("refreshToken", userData.refreshToken);
          logFirebaseEvent("login", {
            user_id_custom: userData.student_username,
            grade: userData.grade,
            school: userData.school,
          })
            .then(() => {
              setAuth(userData);
            })
            .finally(async () => {
              const logBody: LogEventBody = {
                studentUserID: userData.student_username,
                eventName: "dashboard_visit",
                loginEvent: true,
              };
              await logEvent(logBody);
            });
        } else {
          setLoading(false);
          throw new Error(response.message);
        }
      } catch (err) {
        setLoading(false);
        if (
          err &&
          //@ts-ignore
          err.response &&
          //@ts-ignore
          err.response.data &&
          //@ts-ignore
          err.response.data.message === "invalid otp"
        ) {
          newErrors.otp = "Invalid OTP. Please try again.";
        } else if (
          //@ts-ignore
          err.response.data.message === "too many attempts to verify otp"
        ) {
          newErrors.otp =
            "Too many invalid attempts! Please try again after sometime.";
        } else {
          newErrors.otp = "Something went wrong...";
        }
        setErrors(newErrors);
      }
    }
  };

  // useEffect(() => {
  //   if (errors && (errors.userId || errors.otp)) {
  //     setTimeout(() => {
  //       setErrors(undefined);
  //     }, 2000);
  //   }
  // }, [errors]);

  return viewType === "login" ? (
    <div className="login-parent">
      <div className="create-account">
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt=""
            src={NFO}
            style={{
              height: "100px",
              width: "auto",
              marginTop: 100,
              objectFit: "contain",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: 15,
              border: "1px solid rgba(0, 0, 0, 0.05)",
              paddingTop: 30,
              paddingLeft: 35,
              paddingRight: 35,
              paddingBottom: 30,
              marginTop: 50,
              boxShadow: `0px 0px 50px 10px rgb(44, 106, 177,0.1)`,
            }}
          >
            {didSendOtp && (
              <img
                src={backArrowImage}
                alt=""
                onClick={() => {
                  setDidSendOtp(false);
                  setErrors(undefined);
                  setFormData({ ...formData, otp: "" });
                }}
                style={{
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              />
            )}
            <div style={{ fontSize: 26, color: "#2c6ab1" }}>
              Enter your Roll Number
            </div>
            <div style={{ marginTop: 40, marginBottom: 20 }}>
              <BootstrapInput
                autoFocus
                onChange={!didSendOtp ? handleChange : () => {}}
                disabled={didSendOtp}
                name="userId"
                style={{
                  maxWidth: 250,
                  width: window.innerWidth * 0.7,
                  background: "white",
                }}
                inputProps={{
                  style: {
                    // color: "red",
                  },
                }}
                type="text"
                value={formData.userId}
                placeholder="ROLL NUMBER"
              />
            </div>
            {didSendOtp && (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  flexDirection: "row",
                }}
              >
                <BootstrapInput
                  autoFocus
                  onChange={handleChange}
                  name="otp"
                  style={{
                    maxWidth: 250,
                    width: window.innerWidth * 0.7,
                    background: "white",
                  }}
                  type="tel"
                  value={formData.otp}
                  placeholder="Enter OTP"
                  onError={(err) => {
                    console.log(err);
                  }}
                  error={true}
                />
              </div>
            )}
            <div className="otp-buttons-wrapper">
              {errors && (errors.userId || errors.otp) && (
                <Alert
                  style={{
                    position: "relative",
                    // top: 10,
                    // left: 10,
                    // margin: 10,
                    // marginTop: 0,
                  }}
                  severity="error"
                >
                  {errors.userId || errors.otp}
                </Alert>
              )}
              <LoadingButton
                loading={loading}
                onClick={didSendOtp ? handleSubmitOTP : handleSubmitPhoneNumber}
                size="large"
                disabled={
                  (errors
                    ? errors.otp ===
                      "Too many invalid attempts! Please try again after sometime."
                    : false) ||
                  (errors
                    ? errors.userId ===
                      "Too many invalid attempts! Please try again after sometime."
                    : false)
                }
                style={{
                  borderRadius: 100,
                  margin: 10,
                  fontFamily: "Cardo",
                  fontWeight: 400,
                  width: "100%",
                }}
                disableElevation={true}
                variant="contained"
              >
                {didSendOtp ? "Verify & Login" : "Log in"}
              </LoadingButton>
              {didSendOtp && (
                <LoadingButton
                  sx={{
                    "&.Mui-disabled": {
                      color: "rgb(44, 106, 177)",
                      opacity: 1,
                      fontSize: "14px",
                      border: "none",
                      background: "none",
                    },
                    width: "fit-content",
                    justifySelf: "center",
                    alignItems: "center",
                    // backgroundColor: "#CCE4FF",
                    // color: "rgba(44, 106, 177, 1)",
                  }}
                  className="resend-otp-button"
                  loading={loading}
                  // disabled
                  disabled={
                    (resendTimeLeft >= 1 ? true : false) ||
                    (errors
                      ? errors.otp ===
                        "Too many invalid attempts! Please try again after sometime."
                      : false) ||
                    (errors
                      ? errors.userId ===
                        "Too many invalid attempts! Please try again after sometime."
                      : false)
                  }
                  onClick={handleSubmitPhoneNumber}
                  size="large"
                  style={{
                    borderRadius: 100,
                    margin: 10,
                    fontFamily: "Cardo",
                    fontWeight: 400,
                    textTransform: "none",
                  }}
                  disableElevation={true}
                  variant="contained"
                >
                  <div
                    style={{
                      display: "flex",
                      // flexDirection: "column",
                    }}
                  >
                    <a>RESEND OTP</a>
                    {resendTimeLeft > 0 && <a>&nbsp;</a>}
                    {resendTimeLeft > 0 ? resendTimeLeft + "s left" : ""}
                  </div>
                </LoadingButton>
              )}
            </div>
          </div>
        </div>
        <div className="foot">
          <b className="streak">
            Pockvue Solutions Pvt Ltd | An initiative by Streak |
            <a
              style={{ marginLeft: 5 }}
              href="https://nationalfinanceolympiad.com/terms-conditions"
            >
              Cancellation Policy
            </a>
          </b>
        </div>
      </div>
    </div>
  ) : (
    <InvalidSession switchToLogin={() => setViewType("login")} />
  );
};

export default Login;
