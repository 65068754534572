import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footers">
      <b>
        Pockvue Solutions Pvt Ltd | An initiative by Streak |{" "}
        <a
          style={{ marginLeft: 5 }}
          href="https://nationalfinanceolympiad.com/terms-conditions"
        >
          Cancellation Policy
        </a>
      </b>
    </div>
  );
};

export default Footer;
