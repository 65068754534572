export const SESSION_DETAILS_6_8 = [
  {
    name: "Money",
    details: [
      "Explain the evolution from barter to modern money.",
      "Identify key currencies around the world and explain currency exchange.",
      "Differentiate between money and currency, including cryptocurrencies.",
      "Describe paperless money transfers and their benefits.",
    ],
    duration: "1 Hour"
  },
  {
    name: "Banking",
    details: [
      "Describe how banks operate and the types of bank accounts available. ",
      "Differentiate between current and savings accounts, and choose the right one.",
      "Explain money transfer methods: cards, cheques, and digital payments. ",
      "Identify ATM components and describe the roles of bank staff and KYC.",
      "Discuss financial security and common banking frauds.",
    ],
    duration: "1 Hour"
  },
  {
    name: "Financial Planning",
    details: [
      "Understand simple interest calculations and the basics of insurance. ",
      "Identify different types of insurance and their features and importance.",
      "Discuss why saving money is important and types various financial goas. ",
      "Define inflation, and discuss its effects on the economy.",
      "Describe the process of budgeting, the importance of emergency funds, and apply the 50-30-20 rule to personal budgeting.",
    ],
    duration: "1 Hour"
  },
  {
    name: "Investments",
    details: [
      "List different types of investments. ",
      "Explain the basic characteristics of each type of investment. ",
      "Compare the general features of stocks and bonds, highlighting at least one key difference between them.",
      "Assess why someone might choose to invest in real estate versus mutual funds based on their characteristics.",
    ],
    duration: "1 Hour"
  },
  {
    name: "Loans and Credit + 1 Question Paper Discussion",
    details: [
      "Define types of loans (personal, student, home, car).",
      "Explain what collateral is and its role in loans.",
      "Identify smart ways to avoid debt.",
    ],
    duration: "1 Hour"
  },
]


export const SESSION_DETAILS_11_12 = [
  {
    name: "Money",
    details: [
      "Outline the evolution and characteristics of money.",
      "Identify and differentiate between types of money and currencies.",
      "Explain currency exchange, rates, and the importance of currency exchange.",
      "Describe cross-currency triangulation, currency pairs, and their use in trading.",
      "Assess how demand and supply influence money markets and equilibrium.",
      "Summarise key concepts of cryptocurrency, including decentralisation, blockchain, cryptography, types, and frauds.",
      "Compare methods of depreciation: Straight Line Method (SLM) and Written Down Value (WDV).",
    ],
    duration: "1 Hour"
  },
  {
    name: "Banking",
    details: [
      "Discuss how banks raise money and the role of credit scores",
      "Describe financial security measures and common frauds in banking.",
      "Differentiate between loans and deposits, and describe types of bank accounts.",
      "Explain various money transfer systems, including bank transfers, cash transfers, and types of cheques.",
      "Analyse the impact of digitalisation on banking and future trends.",
    ],
    duration: "1 Hour"
  },
  {
    name: "Financial Planning",
    details: [
      "Describe insurance components, explain the benefits, and discuss why insurance is needed.",
      "Explain personal finance concepts: income, expenses, savings, and investing.",
      "Identify assets vs. liabilities, and calculate debt-to-asset ratio and current ratio.",
      "Differentiate between inflation and deflation, and compare simple vs. compound interest.",
      "Apply the 50-30-20 rule for budgeting and discuss the importance of emergency funds.",
    ],
    duration: "1 Hour"
  },
  {
    name: "Investments",
    details: [
      "Define key investment terms (bonds, stocks, mutual funds, real estate).",
      "Explain the difference between saving and investing.",
      "Categorise investments as long-term or short-term.",
      "Compare the advantages and disadvantages  of long-term vs. short-term investments.",
      "Design a basic  diversified investment portfolio.",
    ],
    duration: "1 Hour"
  },
  {
    name: "Loans and Credit",
    details: [
      "Define debt and strategies to avoid it.",
      "Differentiate between secured and unsecured loans.",
      "Explain the importance of a credit score.",
      "Compare types of interest rates on loans.",
      "Identify strategies for managing loans and credit in India.",
    ],
    duration: "1 Hour"
  },
  {
    name: "Tax Planning + 1 Question Paper Discussion",
    details: [
      "Differentiate between GST and income tax.",
      "Describe the features of PPF and post office savings schemes.",
      "Identify the advantages of post office savings schemes.",
    ],
    duration: "1 Hour"
  },
]
