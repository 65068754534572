import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import InvoiceHtml from "./InvoiceHtml";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
import { Button, CircularProgress } from "@mui/material";
import { InvoiceResponse, getInvoiceApi } from "../../../../Utils/api/auth";
import { backArrowImage } from "../../../../Assets/images";

const InvoicePreview = () => {
  const location = useLocation();
  const { invoiceNo } = location.state;
  const [invoiceData, setInvoiceData] = useState<InvoiceResponse>();
  const navigation = useNavigate();

  const getInvoice = async () => {
    getInvoiceApi(invoiceNo).then((response) => {
      //@ts-ignore
      if (response.data.status === "Success") {
        //@ts-ignore
        console.log("invoice", response.data.data);
        //@ts-ignore
        setInvoiceData(response.data.data);
      }
    });
  };
  useEffect(() => {
    getInvoice();
  }, []);

  const [loader, setLoader] = useState(false);
  const options: Options = {
    filename: invoiceData?.InvoiceNo,
    // default is `save`
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.LOW,

    page: {
      margin: Margin.NONE,
      format: "A4",
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // customize any value passed to the jsPDF instance and html2canvas
    // function
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
        orientation: "portrait",
      },

      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
        scale: 3,
      },
    },
  };

  const openPDF = () => {
    setLoader(true);
    generatePDF(() => document.getElementById("wrapper"), options).then(() => {
      setLoader(false);
    });
  };

  return loader ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "absolute",
        zIndex: 100,
        background: "rgba(0,0,0,0.7)",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flex: 1,

        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "20px",
        width: "100%",
      }}
    >
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          flex: 1,
          width: "50%",
          alignSelf: "center",
        }}
      >
        <img
          src={backArrowImage}
          alt=""
          onClick={() => {
            navigation("/dashboard");
          }}
          style={{
            cursor: "pointer",
            marginBottom: "5px",
          }}
        />
        <Button
          autoCapitalize="false"
          variant="contained"
          onClick={openPDF}
          style={{
            marginTop: 50,
            marginBottom: 50,
            height: 40,
            textTransform: "none",
            fontSize: 14,
          }}
        >
          Download Invoice
        </Button>
      </div>
      <div style={{ alignSelf: "center" }}>
        {invoiceData && <InvoiceHtml id="wrapper" data={invoiceData} />}
      </div>
    </div>
  );
};

export default InvoicePreview;
