import React from 'react'

import './ReferralCard.css'
import ReferredPopup from './ReferredPopup'

const ReferralCard = (
  { referredCnt, toggle, setIsReferralModalOpen, copyReferral }: {
    referredCnt: number,
    toggle: boolean,
    setIsReferralModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    copyReferral: () => void
  }
) => {
  return (
    <>
      <ReferredPopup display={toggle} />
      <div className='referral-card'>
        <div className="referred-head">
          <div className={`circle ${referredCnt > 0 ? 'referred' : ''}`}>1</div>
          <div className="line"></div>
          <div className={`circle ${referredCnt > 1 ? 'referred' : ''}`}>2</div>
          <div className="line"></div>
          <div className={`circle ${referredCnt > 2 ? 'referred' : ''}`}>3</div>
        </div>
        <div className="referral-body">
          <p>Refer the NFO to your friends and get a physical copy of the guidebook</p>
        </div>
        <div className="referral-btns">
          <button
            type='button'
            className='btn view-btn'
            onClick={() => setIsReferralModalOpen(true)}
          >
            View details
          </button>
          <button
            type='button'
            className='btn refer-btn'
            onClick={() => {
              referredCnt >= 3 ?
                window.open('https://forms.gle/WRyegYfGpDU2tsep8', '_blank')
                : copyReferral()
            }}
          >
            {referredCnt >= 3 ? 'Claim Reward' : 'Refer now'}
          </button>
        </div>
      </div>
    </>
  )
}

export default ReferralCard