import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import { getBookedSlots, BookedSlots } from "../../Utils/api/auth";
import DoubtClearingHistoryHeader from "./DoubtClearingHistoryComponents/DoubtClearingHistoryHeader";

import "./DoubtClearingHistory.css";

const BOOKED_SLOTS_INIT = {
  slotsBooked: false,
};

const imageUrl =
  "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/Icons/Chat+bibble.png";

const res = {
  data: {
    data: {
      slotsBooked: true,
      slotDetails: [
        {
          created_at: "2024-08-06T13:16:59.502277Z",
          event_id: "0un7f1oqqg4dga2uvv18o4dp9s",
          meeting_link: "https://meet.google.com/ikh-gkef-nbq",
          slot_start_time: "2024-10-13T10:00:00+05:30",
          slot_end_time: "2024-10-13T11:00:00+05:30",
        },
        {
          created_at: "2024-08-06T12:35:09.101064Z",
          event_id: "q3c7cdd0p83qiijm422il1vh4g",
          meeting_link: "https://meet.google.com/bqa-uzqf-vpz",
          slot_start_time: "2024-08-12T11:00:00+05:30",
          slot_end_time: "2024-10-12T19:00:00+05:30",
        },
        {
          created_at: "2024-08-06T12:43:57.479228Z",
          event_id: "6uluenrivskkas6r89htfbl0s4",
          meeting_link: "https://meet.google.com/mhq-nwqv-hsa",
          slot_start_time: "2024-08-08T12:00:00+05:30",
          slot_end_time: "2024-08-08T13:00:00+05:30",
        },
      ],
    },
  },
};

const DoubtClearingHistory = () => {
  const navigate = useNavigate();

  const [bookedSlots, setBookedSlots] = useState<BookedSlots["data"] | any>(
    BOOKED_SLOTS_INIT
  );
  const [loading, setLoading] = useState(true);

  const getSessionStatus = (starttime: string, endtime: string) => {
    const today = new Date();
    const start = new Date(starttime);
    const end = new Date(endtime);

    return today < start
      ? "future"
      : start <= today && today <= end
      ? "present"
      : "past";
  };

  const formatDate = (date: string) => {
    const datetime = new Date(date);
    return `${format(datetime, "EEEE, LLL dd")} at ${format(datetime, "h a")}`;
  };

  const getSlots = async () => {
    try {
      setLoading(true);
      // just comment out the below line to use test data in staging
      const res = await getBookedSlots();
      setBookedSlots(res.data.data);
      console.log("data: ", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSlots();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="dch-page">
      <DoubtClearingHistoryHeader />
      <div className="dch-page-body">
        <div className="dch-container">
          <div className="dch-container-header">
            <span className="text-depressed">Product Description</span>
            {/* <span>Access</span> */}
            <button
              onClick={() => navigate("/doubt-clearing")}
              className="dch-btn"
            >
              Take another Session
            </button>
            {/* <span onClick={() => navigate('/doubt-clearing')}><u>Take another Session</u></span> */}
          </div>
          <div className="dch-sessions w-full">
            {!bookedSlots.slotsBooked ? (
              <p className="dch-none text-depressed">
                You have not booked any Sessions yet
              </p>
            ) : (
              bookedSlots.slotDetails &&
              bookedSlots.slotDetails.map((slot: any, index: number) => (
                <div key={slot.event_id} className="dch-session w-full">
                  <div className="dch-session-photo">
                    <img src={imageUrl} alt="book" width={"100%"} />
                  </div>
                  <div className="dch-session-desc">
                    <p className="dch-session-desc-head">
                      Doubt Clearing Session{" "}
                      {bookedSlots.slotDetails.length - index}
                    </p>
                    {/* <p className="dch-session-desc-body">Your session link will be available <br /> on the registered time slot</p> */}
                    <p className="dch-session-desc-body">
                      All the details about the session can be found here and
                      have been also sent to your registered E-mail ID
                    </p>
                  </div>
                  <div className="dch-session-access">
                    {getSessionStatus(
                      slot.slot_start_time,
                      slot.slot_end_time
                    ) === "past" ? (
                      <div className="dch-session-access-past">
                        <p>&#10004; Session complete</p>
                        <p className="dch-session-desc-body">
                          {format(
                            new Date(slot.slot_start_time),
                            "LLL dd, h a"
                          )}{" "}
                          - {format(new Date(slot.slot_end_time), "h a")}
                        </p>
                      </div>
                    ) : getSessionStatus(
                        slot.slot_start_time,
                        slot.slot_end_time
                      ) === "present" ? (
                      <div className="dch-session-access-present">
                        <button
                          onClick={() => {
                            window.location.href = slot.meeting_link;
                          }}
                          className="dch-btn"
                        >
                          Join session
                        </button>
                      </div>
                    ) : (
                      <div className="dch-session-access-future w-full">
                        <p>
                          <span style={{ color: "rgba(213, 86, 45, 1)" }}>
                            <b>Session scheduled for</b>
                          </span>
                          <br />
                          <span className="">
                            {formatDate(slot.slot_start_time)}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubtClearingHistory;
