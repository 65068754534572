import React from "react";
import { NFO, backArrowImage } from "../../../Assets/images";
import { useNavigate } from "react-router";

import "./NFOSessionHeader.css";

const NFOSessionHeader = () => {
  const navigate = useNavigate();
  return (
    <header className="ns-header">
      <div className="ns-header-summary">
        <button
          className="ns-back-button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img className="ns-back-button-image" src={backArrowImage} alt="<=" />
        </button>
        <div className="ns-header-text-wrapper">
          NFO - Live Training Classes
        </div>
      </div>
      <img className="ns-header-NFO-logo" alt="Nfo logo" src={NFO} />
    </header>
  );
};

export default NFOSessionHeader;
