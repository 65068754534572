import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import './ReferredPopup.css'

const REFER_MSG = 'Referral link copied to clipboard. Share it with your friends'

interface Props {
    display: boolean;
}

const ReferredPopup: React.FC<Props> = ({ display }) => {
    return (
        <AnimatePresence>
            {
                display &&
                <motion.div
                    key='div'
                    className="popup-container text-copied"
                    initial={{ opacity: 0, y: '-100%' }}
                    animate={{ opacity: 1, y: '0%' }}
                    exit={{ opacity: 0, y: '-100%' }}
                    transition={{
                        type: 'spring',
                        delay: 0,
                        stiffness: 90,
                    }}
                >
                    <div className='msg'>{REFER_MSG}</div>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default ReferredPopup