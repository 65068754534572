import React, { ChangeEvent } from "react";
import styled from "@emotion/styled";
import { InputBase, alpha } from "@mui/material";
import { format, longFormatters } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DatePicker } from '@mui/x-date-pickers';



export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 3,
  },
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#F3F6F9",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 18,
    padding: "10px 12px",
    height: "30px",
    color: "#2C6AB1",
    fontFamily: ["Cardo"].join(","),
    "&:focus": {
      boxShadow: `${alpha("#2C6AB1", 0.25)} 0 0 0 0.2rem`,
      borderColor: "#fff",
    },
    "&::placeholder": {
      color: "rgb(44, 106, 177)",
      opacity: 0.8,
    },
  },
}));

interface CustomDatePickerProps {
  selectableDates: string[] | string;
  onChange: (e: any) => void;
  value: string;
  disabled: boolean
}

export const CustomDatePicker = ({ selectableDates, onChange, value, disabled }: CustomDatePickerProps) => {
  const isSelectable = (date: Date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    return selectableDates.includes(formattedDate);
  };  

  // const renderDay = (day: Date, _value: any, DayComponentProps: any) => {
  //   if (!isSelectable(day)) {
  //     return <span {...DayComponentProps} />;
  //   }
  //   return <span {...DayComponentProps} />;
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        name="date"
        disabled={disabled}
        // views={['day']}
        shouldDisableDate={(date: Date) => !isSelectable(date)}
        onChange={onChange}
        value={new Date(value)}
        slotProps={{
          textField: {
            // Don't remove the conditional value, new Date('') will trigger red outline in the begining
            value: value? new Date(value): null,
            onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => e.preventDefault,
            onChange: () => {},
          },
        }}
        sx={{
          width: '100%',
          backgroundColor: '#F3F6F9',
          color: "#2C6AB1",
          border: "1px solid #E0E3E7",
          borderRadius: '10px',
          fontSize: 18,
          fontFamily: ["Cardo"].join(","),
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              boxShadow: `${alpha("#2C6AB1", 0.25)} 0 0 0 0.2rem`,
              borderColor: '#fff',
            },
          },
          '& .MuiInputBase-input': {
            color: 'rgb(44, 106, 177)',
            // opacity: 0.8,
            fontFamily: ["Cardo"].join(","),
          },
          '& .MuiInputLabel-root': {
            color: 'rgb(44, 106, 177)',
            opacity: 0.8,
            fontFamily: ["Cardo"].join(","),
          },
        }}
      />
    </LocalizationProvider>
  );
};
