import React, { useContext, useEffect, useState } from "react";
import "../DashboardComponentsStyles.css";
import {
  mockQuizImage,
  OlympiadPreviewImage,
  ProductInfoIcon,
} from "../../../../Assets/images";
import { fetchSamplePapers } from "../../../../Utils/MockQuizAPIs/MockQuizAPIs";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../App";
import PreviewModal from "../Bookshelf/PreviewModal";
import OlympiadLottie from "../../../../Assets/lotties/OlympiadLottie.json";
import Lottie from "lottie-react";
import { LogEventBody, logEvent } from "../../../../Utils/api/auth";

interface MockQuizCardProps {
  cbHandleMockClick: () => void;
}

const ITEM_ID = 2;

const MobileMockQuizCard = (props: MockQuizCardProps) => {
  const navigate = useNavigate();

  const {
    auth: {
      created_at,
      school_id,
      student_username,
      name,
      phone,
      email,
      grade,
      batch_id,
      invite_sent,
      report_generated,
      exam_notification_sent,
      report_id,
      paid_for_mock_olympiad,
      notify_for_new_mock_olympiads,
      school,
      examDate,
    },
  } = useContext(AuthContext);

  const [numExamsTaken, setNumExamsTaken] = useState<number | null>(null);
  const [purchasedMock, setPurchasedMock] = useState<boolean | null>(null);
  const [totalExamPapers, setTotalExamPapers] = useState<number | null>(null);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  const handleShowInfoModal = async () => {
    setShowInfoModal(true);
    // uncomment if required

    // const logBody: LogEventBody = {
    //   studentUserID: student_username,
    //   eventName: 'view_item_info',
    //   productIDs: [ITEM_ID]
    // }
    // const res = await logEvent(logBody)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSamplePapers();
        if (response.status === 200 && response.data && response.data.data) {
          const QPArray = response.data.data;
          let examsTaken = 0;
          for (let i = 0; i < QPArray.length; i++) {
            if (QPArray[i].examTaken) {
              examsTaken++;
            }
          }
          setNumExamsTaken(examsTaken);
          setTotalExamPapers(QPArray.length);
        }
      } catch (error) {
        console.error("Error fetching Sample Papers Metadata:", error);
      }
    };
    fetchData();
  }, []);

  console.log({ paid_for_mock_olympiad, numExamsTaken });

  return (
    <div className="mobile-mock-quiz-card">
      {numExamsTaken !== null &&
        numExamsTaken > 0 &&
        !paid_for_mock_olympiad && (
          <Lottie
            animationData={OlympiadLottie}
            style={{
              position: "relative",
              top: 0,
              height: 60,
              width: 120,
              right: 0,
            }}
            loop={true}
          />
        )}
      <img
        style={{
          height: "50%",
          width: "50%",

          objectFit: "contain",
          alignSelf: "center",
          marginTop: 10,
          marginBottom: 10,
          marginRight: 130,
        }}
        alt="Image"
        src={mockQuizImage}
      />
      <div className="mobile-mock-texts">
        <div style={{ display: "flex" }}>
          <div className="mobile-mock-title">NFO Practice Test</div>
          {!paid_for_mock_olympiad && (
            <img
              onClick={handleShowInfoModal}
              src={ProductInfoIcon}
              style={{
                height: "20px",
                width: "20px",
                marginTop: 7,
                cursor: "pointer",
                marginLeft: 20,
              }}
            />
          )}
        </div>
        <p className="mobile-mock-subtitle">
          Measure your readiness before the NFO Practice Test with real-time
          practice exams.
        </p>
      </div>
      <div className="mobile-mock-all-buttons-wrap">
        {/* {numExamsTaken !== null &&
          numExamsTaken == 0 &&
          !paid_for_mock_olympiad && (
            <button
              className="nfo-portal-v2-button-3"
              onClick={props.cbHandleMockClick}
            >
              <div className="">Attempt Your First Free Exam</div>
            </button>
          )} */}
        {!paid_for_mock_olympiad && (
          <button
            style={{ marginTop: 20, marginBottom: 20 }}
            className="nfo-portal-v2-button-3"
            onClick={() => {
              const token = localStorage.getItem("accessToken");
              openInSameTab(
                `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${ITEM_ID}&token=${token}&userName=${student_username}`
              );
            }}
          >
            <div className="">Buy NFO Practice Test</div>
          </button>
        )}
        {/* {numExamsTaken !== null &&
          numExamsTaken > 0 &&
          !paid_for_mock_olympiad && (
            <button
              className="nfo-portal-v2-button-3 retry-button"
              onClick={props.cbHandleMockClick}
            >
              <div className="nfo-portal-v2-text-wrapper-10 retry-text">
                Retry Mock Olympiad
              </div>
            </button>
          )} */}
        {paid_for_mock_olympiad && (
          <div
            style={{ marginTop: 20, marginBottom: 20 }}
            className="mobile-after-mock-wrapper"
          >
            <button
              className="nfo-portal-v2-button-3 view-button"
              onClick={props.cbHandleMockClick}
            >
              <div className="nfo-portal-v2-text-wrapper-10 view-text">
                View
              </div>
            </button>
            <div className="sample-done">
              {numExamsTaken}/{totalExamPapers} Papers Solved
            </div>
          </div>
        )}
      </div>
      <PreviewModal
        itemName={"NFO Practice Test"}
        buttonText="Buy 12 Practice Tests Now"
        onPress={() => {
          const token = localStorage.getItem("accessToken");
          // openInSameTab(
          //   `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${2}&token=${token}&userName=${student_username}`
          // );
          props.cbHandleMockClick();
        }}
        imgSrc={OlympiadPreviewImage}
        basePrice="499"
        openModal={showInfoModal}
        cbHandleCloseModal={() => {
          setShowInfoModal(false);
        }}
        currentDescription={
          "NFO Practice Test offers an online environment mirroring the actual Olympiad. With the NFO Practice Test, you can simulate exam conditions with 12 authentic question papers and timed assessments! Understand your weaknesses with detailed performance reports to aid preparation."
        }
        sellingPrice={"299"}
      />
    </div>
  );
};

export default MobileMockQuizCard;
