import React, { useEffect, useState } from "react";
import Order from "../Order/Order";
import { OrderedItems, getPastOrders } from "../../../../Utils/api/auth";

const Orders = () => {
  const [orders, setOrders] = useState<OrderedItems[]>([]);

  useEffect(() => {
    getPastOrders().then((res) => {
      //@ts-ignore
      setOrders(res.data.data.orderedItems);
    });
  }, []);

  return (
    <div className="orders">
      <div className="orders-title">
        <div className="orders-text-wrapper-2">Product description</div>
      </div>
      {orders.length > 0 ? (
        orders.map((e) => {
          return (
            <Order
              itemDetails={e.itemDetails}
              bundle={e.bundle}
              invoiceNo={e.invoiceNo}
            />
          );
        })
      ) : (
        <div>No Ordered Items</div>
      )}
    </div>
  );
};

export default Orders;
