import axios from "axios";
import Cookies from "js-cookie";
import { prod_api } from "../api/prodApi";

const api = axios.create({
  baseURL: prod_api, // Replace with your API base URL
});

// Function to refresh the access token using the refresh token
const refreshAccessToken = async () => {
  try {
    const response = await axios.post(`${prod_api}/refresh-token`, {
      refresh_token: localStorage.getItem("refreshToken"), // Replace with your implementation to retrieve refresh token
    });

    const newAccessToken = response.data.token;
    const newRefreshToken = response.data.refresh_token;
    localStorage.setItem("accessToken", newAccessToken);
    localStorage.setItem("refreshToken", newRefreshToken);
    return newAccessToken;
  } catch (error) {
    // Handle refresh token failure (e.g., logout user)
    console.error("Failed to refresh access token:", error);
    // Perform logout or any other action here
    // Example: logoutUser();
    sessionStorage.clear();
    window.location.reload();
    localStorage.clear();
    throw error;
  }
};

// Axios request interceptor for adding the access token to requests
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor to refresh the access token if it's expired
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log(originalRequest);

    // Check if the error is due to an expired token
    if (
      error.response.status === 400 &&
      !originalRequest._retry &&
      originalRequest.url !== "user-login-send-otp" &&
      originalRequest.url !== "user-login-verify-otp"
    ) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers.Authorization = newAccessToken;
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh fails, perform logout or any other action
        console.error("Failed to refresh access token:", refreshError);
        // Example: logoutUser();\
        Cookies.remove("accessToken");
        sessionStorage.clear();
        window.location.reload();
        localStorage.clear();
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
