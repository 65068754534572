import React, { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Layout from "./layouts/Layout";
import Login from "./Pages/Login/Login";
import PdfPreview from "./Pages/PdfPreview/PdfPreview";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { CircularProgress } from "@mui/material";
import { getDetails, LogEventBody, logEvent } from "./Utils/api/auth";
import OrderHistory from "./Pages/OrderHistory/OrderHistory";
import "./overrides";
import HTMLViewer from "./Pages/Dashboard/DashboardComponents/ExamCard/HTMLViewer";
import ReportViewer from "./Pages/Dashboard/DashboardComponents/ExamCard/ReportViewer";
import InvoicePreview from "./Pages/OrderHistory/Components/InvoiceViewer/InvoiceViewer";

import DoubtClearing from "./Pages/DoubtClearing/DoubtClearing";
import DoubtClearingHistory from "./Pages/DoubtClearingHistory/DoubtClearingHistory";
import NFOSession from "./Pages/NFOSession/NFOSession";
import NFOSessionHistory from "./Pages/NFOSessionHistory/NFOSessionHistory";

import { initializeApp } from "firebase/app";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { firebaseConfig } from "./Utils/firebase/firebaseConfig";

export const AuthContext = createContext({} as any);

//@ts-ignore
const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [invalidSession, setInvalidSession] = useState(false);

  const setParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const userName = urlParams.get("userName");
    const accessToken = urlParams.get("token");
    const refreshToken = urlParams.get("refreshToken");

    if (accessToken && refreshToken && userName) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("x_id", userName);
    }

    const authUuid = localStorage.getItem("x_id");
    return { authUuid };
  };

  const checkExistingSession = async () => {
    const { authUuid } = setParams();
    // const authUuid = localStorage.getItem("x_id");

    if (authUuid) {
      const isSessionValid = await getDetails(authUuid);
      if (isSessionValid) {
        //@ts-ignore
        if (isSessionValid.data.status == "Success") {
          //@ts-ignore
          setAuth(isSessionValid.data.data);
          setInvalidSession(false);
          // const logBody: LogEventBody = {
          //   studentUserID: isSessionValid.data.data.student_username,
          //   eventName: 'dashboard_visit',
          //   loginEvent: false
          // }
          // await logEvent(logBody)
        }
      } else {
        setInvalidSession(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    checkExistingSession();
  }, []);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <AuthContext.Provider
          value={{ auth, setAuth, invalidSession, loading, setLoading }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </div>
  );
};

const AppRoutes = () => {
  const { auth } = useContext(AuthContext);
  console.log("Deployment: 16/05/2024: 6:10 pm ");

  return auth ? (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<Layout />}>
          <Route path="*" element={<Navigate to={"/dashboard"} replace />} />
          <Route path="/dashboard/:param" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/pdf-preview" element={<PdfPreview />} />
          <Route path="/orderHistory" element={<OrderHistory />} />
          <Route path="/report-viewer" element={<ReportViewer />} />
          <Route path="/certificate-viewer" element={<HTMLViewer />} />
          <Route path="/invoice-viewer" element={<InvoicePreview />} />
          <Route path="/doubt-clearing" element={<DoubtClearing />} />
          <Route
            path="/doubt-clearing-history"
            element={<DoubtClearingHistory />}
          />
          <Route path="/nfo-classes" element={<NFOSession />} />
          <Route path="/nfo-classes-history" element={<NFOSessionHistory />} />
          <Route path="/logout" element={<></>} />
        </Route>
      </Routes>
    </BrowserRouter>
  ) : (
    <Login />
  );
};

const App = () => {
  return (
    <div>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </div>
  );
};

export default App;
