import React from 'react'
import { NFO, backArrowImage } from '../../../Assets/images'
import { useNavigate } from "react-router";

import './DoubtClearingHeader.css'

const DoubtClearingHeader = () => {
	const navigate = useNavigate();
	return (
		<header className="dc-header">
			<div className="dc-header-summary">
				<button
					className="dc-back-button"
					onClick={() => {
						navigate(-1);
					}}
				>
					<img
						className="dc-back-button-image"
						src={backArrowImage}
						alt="<="
					/>
				</button>
				<div className="dc-header-text-wrapper">
					NFO Expert Assistance
				</div>
			</div>
			<img className="dc-header-NFO-logo" alt="Nfo logo" src={NFO} />
		</header>
	)
}

export default DoubtClearingHeader