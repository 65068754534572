import React from "react";
import "./DashboardHeader.css";
import { NFO, clock } from "../../../../Assets/images";
import { useNavigate } from "react-router";

const DashboardHeader = () => {
  const navigate = useNavigate();
  return (
    <header className="dashboard-header">
      <img className="dashboard-header-NFO-logo" alt="Nfo logo" src={NFO} />
      <button
        className="dashboard-header-summary"
        onClick={() => {
          navigate("/orderHistory");
        }}
      >
        <img src={clock} alt="O" />
        <div className="dashboard-header-orderHistory-text">
          Purchase History
        </div>
      </button>
    </header>
  );
};

export default DashboardHeader;
