import { Modal } from "@mui/material";
import React, { useContext } from "react";
import { arrowLeftWhite } from "../../../../Assets/images";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../App";

interface accessProductPropsInterface {
  cbHandleCloseModal: () => void;
  openModal: boolean;
  currentDescription: string;
  sellingPrice: string;
  basePrice: string;
  imgSrc: string;
  onPress: () => void;
  buttonText: string;
  itemName: string;
}

const PreviewModal = (props: accessProductPropsInterface) => {
  const navigate = useNavigate();

  const {
    auth: { student_username },
  } = useContext(AuthContext);

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  return (
    <Modal
      open={props.openModal}
      onClose={props.cbHandleCloseModal}
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="item-info-wrap">
        <button
          className="item-info-back-button"
          onClick={props.cbHandleCloseModal}
        >
          <img
            style={{
              top: "-5px",
            }}
            src={arrowLeftWhite}
            alt="&lt;-"
          />
          <a
            style={{
              marginLeft: "5px",
            }}
          >
            Go back
          </a>
        </button>
        <br />
        <div className="item-info-container">
          <img
            src={props.imgSrc}
            style={{
              height: 130,
              width: 130,
              alignSelf: "center",
              objectFit: "contain",
            }}
          />
          <div className="product-question">{props.itemName}</div>

          <div style={{ paddingTop: 10, fontSize: 18, textAlign: "center" }}>
            {props.currentDescription}
          </div>
          <div style={{ paddingTop: 10, textAlign: "center" }}>
            Price: <del>₹{props.basePrice}</del> ₹{props.sellingPrice}
          </div>
          <div
            onClick={props.onPress}
            style={{
              backgroundColor: "#2C6AB1",
              color: "#fff",
              fontSize: 18,
              borderRadius: 100,
              alignSelf: "center",
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 10,
              paddingBottom: 10,
              cursor: "pointer",
            }}
          >
            {props.buttonText}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewModal;
