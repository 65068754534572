import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import './ErrorModal.css'

const ERR_MSG = 'Something went wrong.. Please try again..'

interface ErrorModalProps {
    display: boolean;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ display }) => {
    return (
        <AnimatePresence>
            {
                display &&
                <motion.div
                    key='error-div'
                    className="dc-error-container text-error"
                    initial={{ opacity: 0, y: '-100%' }}
                    animate={{ opacity: 1, y: '0%' }}
                    exit={{ opacity: 0, y: '-100%' }}
                    transition={{
                        type: 'spring',
                        delay: 0,
                        stiffness: 90,
                    }}
                >
                    <div className='dc-error'>{ERR_MSG}</div>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default ErrorModal