// Import the functions you need from the SDKs you need

import {
  CustomEventName,
  EventNameString,
  getAnalytics,
  logEvent,
} from "firebase/analytics";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAZg_Q0xt18udacGMNlpksTs5R72NcuTBg",
  authDomain: "nationalfinanceolympiad.firebaseapp.com",
  projectId: "nationalfinanceolympiad",
  storageBucket: "nationalfinanceolympiad.appspot.com",
  messagingSenderId: "252764224636",
  appId: "1:252764224636:web:e03a419a33c647c74bddf9",
  measurementId: "G-6NS0N98LJ7",
};

const app = initializeApp(firebaseConfig);

export const fAnalytics = getAnalytics(app);

export const logFirebaseEvent = async (eventName: string, parameters: {}) => {
  try {
    logEvent(fAnalytics, eventName, parameters);
    return true;
  } catch (err) {
    return false;
  }
};
