import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

import { AuthContext } from "../../../App";
import "./SessionDetails.css";
import { playButton, downArrow } from "../../../Assets/svgs";

import {
  SESSION_DETAILS_6_8,
  SESSION_DETAILS_11_12,
} from "../docs/sessionInfo";

const SessionDetails = () => {

  const { 
    auth: {
      grade
    }
   } = useContext(AuthContext)
  const navigate = useNavigate()


  // currently no session for grade 9th or 10th
  if (grade === 9 || grade === 10) navigate("/dashboard");

  const [selectedTopic, setselectedTopic] = useState<number | null>(0);
  const session_details =
    grade < 11 ? SESSION_DETAILS_6_8 : SESSION_DETAILS_11_12;

  const toggleSelect = (topicInd: number) => {
    setselectedTopic(topicInd === selectedTopic ? null : topicInd);
  };

  return (
    <div className="nsd-container">
      <div className="nsd-header">
        <p className="nsd-header-1">NFO - Live Training Classes</p>
        <p className="nsd-header-2">
          The NFO - Live Training Classes are designed to enhance student
          preparation for the upcoming finance Olympiad, providing expert
          guidance from a tutor to support them throughout their learning
          journey
        </p>
        <div className="nsd-header-btm">
          <div className="nsd-header-btm-div">
            Number of Sessions: {grade < 11 ? "5" : "6"}
          </div>
          <div className="nsd-header-btm-div">Duration: 1 Hour</div>
        </div>
      </div>
      <div className="nsd-body">
        {session_details.map((topic, index) => (
          <div key={index}>
            <div className="line"></div>
            <div className="topic">
              <div
                className="topic-heading"
                onClick={() => toggleSelect(index)}
              >
                <div className="topic-name">
                  <span>
                    {index + 1}. {topic.name}
                  </span>
                  <motion.img
                    src={downArrow}
                    alt="arrow"
                    className="topic-name-arrow"
                    animate={{ rotate: selectedTopic === index ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  />
                </div>
                <div className="topic-duration">
                  <img
                    src={playButton}
                    alt="play"
                    className="topic-duration-play"
                  />
                  <span>Duration: {topic.duration}</span>
                </div>
              </div>
              <motion.ul
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: selectedTopic === index ? "auto" : 0,
                  opacity: selectedTopic === index ? 1 : 0,
                }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.3,
                }}
                style={{ overflow: "Hidden" }}
              >
                {topic.details.map((detail, ind2) => (
                  <li className="sub-topic" key={ind2}>
                    {detail}
                  </li>
                ))}
              </motion.ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SessionDetails;
