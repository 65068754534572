import React, {
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  useContext,
} from "react";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { motion, AnimatePresence } from "framer-motion";

import { AuthContext } from "../../App";
import {
  getUserSlots,
  saveBookingDetails,
  LogEventBody,
  logEvent,
} from "../../Utils/api/auth";
import DoubtClearingHeader from "./DoubtClearingComponents/DoubtClearingHeader";
import ErrorModal from "./DoubtClearingComponents/ErrorModal";
import { BootstrapInput, CustomDatePicker } from "../../Components/InputField";

import "./DoubtClearing.css";

const ITEM_ID = 28
const DESC_LENGTH = 200;
const ERROR_TIMEOUT = 5000;

const DoubtClearing = () => {
  const {
    auth: { student_username },
  } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    desc: "",
    date: "",
    timeslot: -1,
  });
  const [descLength, setDescLength] = useState(0);
  const [userSlots, setUserSlots] = useState<any>("");
  const [datesAvl, setDatesAvl] = useState(false);

  // states for updating components
  const [dateSelected, setDateSelected] = useState(false);
  const [timeSelected, setTimeSelected] = useState(false);
  const [hasProceed, setHasProceed] = useState(false);
  const [errorProceed, setErrorProceed] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);

  const proceedContainerRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name == "desc") {
      if (value.length > DESC_LENGTH) {
        return;
      } else {
        setDescLength(value.length);
      }
    }
    // console.log("input: ", name);
    setFormData({ ...formData, [name]: value });
  };

  const handleDateSelect = (date: Date) => {
    setFormData({
      ...formData,
      ["date"]: format(date, "yyyy-MM-dd"),
      ["timeslot"]: -1,
    });
    setDateSelected(true);
    setTimeSelected(false);
    // console.log(format(date, 'yyyy-MM-dd'));
  };
  const handleTimeSelect = (ind: number) => {
    setFormData({ ...formData, ["timeslot"]: ind });
    setTimeSelected(true);
  };

  useEffect(() => {
    // don't remove it.. need some time to slide in the proceed container
    setTimeout(() => {
      if (window.innerWidth <= 768 && proceedContainerRef.current) {
        proceedContainerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 400);
  }, [timeSelected, formData.timeslot]);

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  const formatTime = (datetime: string) => {
    const date = new Date(datetime);
    return format(date, "hh a").toLowerCase();
  };

  const getTimeSpan = (index: number) => {
    return `${formatTime(
      userSlots.dateWiseSlot[formData.date][index].starttime
    )} - ${formatTime(userSlots.dateWiseSlot[formData.date][index].endtime)}`;
  };

  const onRefresh = () => setRefresh((prev) => !prev);

  const onClear = () => {
    setFormData({ ...formData, date: "", timeslot: -1 });
    setDateSelected(false);
    setTimeSelected(false);
    setHasProceed(false);
  };

  const handleErrorProceed = () => {
    setErrorProceed(true);
    onClear();
    onRefresh();
    setTimeout(() => {
      setErrorProceed(false);
    }, ERROR_TIMEOUT);
  };

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setHasProceed(true);

    const selectedSlot =
      userSlots.dateWiseSlot[formData.date][formData.timeslot].starttime;
    const selectedSlotTime = selectedSlot.split("+")[0];

    try {
      const saveDetailsResponse = await saveBookingDetails({
        questionDescription: formData.desc,
        timeSlot: selectedSlotTime,
      });
      console.log(saveDetailsResponse.data);
      //@ts-ignore
      if (saveDetailsResponse.data.status === "Success") {
        const token = localStorage.getItem("accessToken");
        if (token) {
          openInSameTab(
            //@ts-ignore
            `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=${userSlots.storeItemID}&token=${token}&userName=${student_username}&bookingId=${saveDetailsResponse.data.data.bookingDetailsID}`
          );
        }
      } else {
        handleErrorProceed();
      }

      // for testing purpose
      // setTimeout(() => {
      // 	handleErrorProceed()
      // }, 1000);
    } catch (error) {
      console.error(error);
      handleErrorProceed();
    }
  };

  const getSlots = async () => {
    try {
      setLoading(true);
      const res = await getUserSlots();
      setUserSlots(res.data.data);
      if (
        typeof res.data.data !== "string" &&
        res.data.data.datesAvailable?.length > 0
      )
        setDatesAvl(true);
      console.log("data: ", res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSlots();
  }, [refresh]);

  useEffect(() => {
    const logBody: LogEventBody = {
      studentUserID: student_username,
      eventName: 'view_item_info',
      productIDs: [ITEM_ID]
    }
    logEvent(logBody)
  }, [])

  return (
    <>
      <ErrorModal display={errorProceed} />
      <div className="dc-page">
        <DoubtClearingHeader />
        <form onSubmit={handleSubmit} className="dc-page-body">
          <div className="dc-container">
            {/* <p className="dc-heading">Share the topics or questions you'd like to discuss <br /> with our expert tutor</p> */}
            <div className="dc-form">
              <div className="dc-form-label-container">
                <p className="dc-form-label">
                  Share the topics or questions you'd like to discuss <br />{" "}
                  with our expert tutor
                </p>
              </div>
              <div className="dc-form-description-container">
                <BootstrapInput
                  autoFocus
                  name="desc"
                  onChange={handleChange}
                  placeholder="Describe your doubt/topic in brief"
                  value={formData.desc}
                  className="dc-form-description"
                  fullWidth
                  multiline
                  rows={5}
                  disabled={hasProceed}
                  required
                />
                <p className="desc-length">
                  {descLength}/{DESC_LENGTH}
                </p>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="dc-form-label-container">
                    {/* <span>Select from available dates:</span> */}
                    <span className="dc-form-label">
                      Select a date for your session:
                    </span>
                    {!datesAvl && (
                      <span className="not-available">
                        No dates available now
                      </span>
                    )}
                  </div>
                  <div className="dc-form-datepicker-container">
                    {formData.date ? (
                      <div className="dc-form-datefield">
                        {format(formData.date, "MMM dd, yyyy")}
                      </div>
                    ) : (
                      <div className="dc-form-datefield">no date selected</div>
                    )}
                    <CustomDatePicker
                      selectableDates={userSlots.datesAvailable}
                      onChange={handleDateSelect}
                      value={formData.date}
                      disabled={!datesAvl || hasProceed}
                    />
                  </div>
                  {dateSelected ? (
                    <div className="dc-form-label-container">
                      <span className="dc-form-label">Select a time-slot:</span>
                      <span className="not-available">
                        {!userSlots.dateWiseSlot[formData.date].some(
                          (slot: any) => slot.available
                        ) ? (
                          <>
                            No time-slots available
                            <br />
                            Please select an alternate date
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  ) : datesAvl ? (
                    <p className="dc-form-label text-depressed">
                      Select a Date to book your time-slot
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {dateSelected && (
                    <div className="timeslots-container">
                      {userSlots.dateWiseSlot[formData.date].map(
                        (slot: any, index: number) => (
                          <div className="timeslot-button-container">
                            <button
                              type="button"
                              key={index}
                              className="timeslot-button"
                              disabled={!slot.available || hasProceed}
                              onClick={() => handleTimeSelect(index)}
                              style={{
                                backgroundColor:
                                  index === formData.timeslot
                                    ? "#023b8f10"
                                    : "",
                              }}
                            >
                              {formatTime(slot.starttime)} -{" "}
                              {formatTime(slot.endtime)}
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {/* <button onClick={onClear}>clear</button> */}
                </>
              )}
            </div>
          </div>
          <AnimatePresence>
            {!loading && dateSelected && timeSelected && (
              <motion.div
                className="dc-container dc-proceed-container shadow-light"
                ref={proceedContainerRef}
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: "0%" }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{
                  type: "just",
                  delay: 0,
                  stiffness: 90,
                }}
              >
                <p className="dc-proceed-heading">
                  You are booking a NFO Expert Session on:
                  <p className="dc-proceed-subheading">
                    {format(new Date(formData.date), "eeee, LLLL dd")}
                    <br />
                    {getTimeSpan(formData.timeslot)}
                  </p>
                </p>
                <div className="line"></div>
                <div className="dc-proceed-body">
                  <p className="dc-proceed-price">
                    <span>Subtotal (1 item):</span>
                    <span>&#8377; 499</span>
                  </p>
                  <span className="text-depressed">inclusive of all taxes</span>
                </div>
                <button
                  type="submit"
                  className="dc-proceed-button"
                  disabled={hasProceed}
                >
                  {hasProceed ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "25px",
                        color: "#fff",
                      }}
                    >
                      <CircularProgress size={25} />
                    </div>
                  ) : (
                    "Proceed"
                  )}
                </button>
                <p className="dc-proceed-footer text-depressed">
                  You will receive the link for the session via email and on the
                  NFO Student Hub
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </form>
      </div>
    </>
  );
};

export default DoubtClearing;
