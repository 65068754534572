import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'

const Layout = () => {
    return (
        <div
            style={{
                minHeight: "100vh",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'rgba(241, 250, 254, 1)',
            }}>
            <Outlet />
            <Footer />
        </div>
    )
}

export default Layout