import React from 'react'
import ReferredPopup from './ReferredPopup'

import './ReferralModal.css'
import { OlympiadPreviewImage } from '../../../../Assets/images'

const ReferralModal = (
  { toggle, referredStudents, copyReferral }: {
    toggle: boolean,
    referredStudents: string[],
    copyReferral: () => void
  }) => {

  return (
    <>
      <ReferredPopup display={toggle} />
      <div className='referral-modal'>
        <img src={OlympiadPreviewImage} alt="refer" width={'200px'} />
        <div className="refer-body">
          <div className="refer-header">
            <p>Refer NFO</p>
          </div>
          <div className="refer-desc">
            <p>
              Get your friends & family to register for the NFO and get a physical copy of the NFO guidebook in your hands.
            </p>
          </div>
          <button
            className="refer-btn"
            type='button'
            onClick={() => copyReferral()}
          >
            Share Referral Link
          </button>
        </div>
        <div className="line"></div>
        <div className="referred-section">
          <div className="referred-header">
            <p>Once someone registers using your referral link, their details will appear here</p>
          </div>
          <div className="referred-body">
            {
              [0, 1, 2].map((index) => (
                <div key={index} className='referred-person'>
                  <div className={`circle ${referredStudents[index] ? 'referred' : ''}`}>
                    {index + 1}
                  </div>
                  <span className={` ${referredStudents[index] ? 'referred-name' : 'disabled'}`}>
                    {referredStudents[index] || 'No registration yet'}
                  </span>
                </div>
              ))
            }
          </div>
        </div>
        {
          referredStudents.length >= 3 &&
          <button
            className="claim-reward"
            type='button'
            onClick={() => window.open('https://forms.gle/WRyegYfGpDU2tsep8', '_blank')}
          >
            Claim your Reward now
          </button>
        }
      </div>
    </>
  )
}

export default ReferralModal