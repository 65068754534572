import { Modal } from "@mui/material";
import React, { useContext } from "react";
import { arrowLeftWhite } from "../../../../Assets/images";
import { ItemDetails } from "../../../../Utils/api/auth";
import "./AccessProductsModal.css";
import { chevronRight } from "../../../../Assets/svgs";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../App";

interface accessProductPropsInterface {
  cbHandleCloseModal: () => void;
  openModal: boolean;
  itemDetailsArray: ItemDetails[];
}

const AccessProductsModal = (props: accessProductPropsInterface) => {
  const navigate = useNavigate();

  const {
    auth: {
      created_at,
      school_id,
      student_username,
      name,
      phone,
      email,
      grade,
      batch_id,
      invite_sent,
      report_generated,
      exam_notification_sent,
      report_id,
      paid_for_mock_olympiad,
      notify_for_new_mock_olympiads,
      school,
      examDate,
    },
  } = useContext(AuthContext);

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  return (
    <Modal
      open={props.openModal}
      onClose={props.cbHandleCloseModal}
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="item-info-wrap">
        <button
          className="item-info-back-button"
          onClick={props.cbHandleCloseModal}
        >
          <img
            style={{
              top: "-5px",
            }}
            src={arrowLeftWhite}
            alt="&lt;-"
          />{" "}
          <a
            style={{
              marginLeft: "5px",
            }}
          >
            Go back
          </a>
        </button>
        <br />
        <div className="item-info-container">
          <div className="product-question">
            What product do
            <br />
            you wish to access ?
          </div>
          {props.itemDetailsArray.map((item, index) => (
            <div className="item-info-content-wrapper">
              <button
                className="item-info-content-button"
                onClick={() => {
                  if (item.id === 2) {
                    const token = localStorage.getItem("accessToken");
                    if (token) {
                      openInSameTab(
                        `https://olympiad.nationalfinanceolympiad.com?token=${token}&userName=${student_username}`
                      );
                    } else {
                      console.log("accesstoken not found in localstorage");
                    }
                  } else {
                    navigate("/pdf-preview", {
                      state: {
                        type: item.name,
                        link: item.link,
                        goBack: true,
                      },
                    });
                  }
                }}
              >
                <img className="item-info-image" src={item.image} />
                <div className="item-info-title">{item.name}</div>
                {/* <div className="item-info-desc">{item.description}</div> */}
                <button className="chevron-right-button">
                  <img src={chevronRight} alt=">" />
                </button>
              </button>
              {index !== props.itemDetailsArray.length - 1 && (
                <div className="line" />
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AccessProductsModal;
