import React from 'react';
import Orders from '../Orders/Orders';

const OrderSubtitle = () => {
  return (
    <div className="order-subtitle">
      <Orders />
    </div>
  )
}

export default OrderSubtitle;