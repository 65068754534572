import React, { FC, useState } from "react";
import "./Carousel.css";
import {
  Disappointed,
  good,
  lovedIt,
  neutral,
  slightly,
} from "../../Assets/images";
import { FeedbackData, FeedbackQuestion } from "../../Utils/api/auth";

interface Question {
  id: number;
  question_answer: {
    question: string;
    options?: Record<string, string>;
  };
  type: "rating" | "mcq" | "text";
  is_mandatory: boolean;
}

interface CarouselProps {
  questions: Question[];
  onSubmit: (responses: FeedbackData) => void;
  submitButtonText: string;
}

const Carousel: FC<CarouselProps> = ({
  questions,
  onSubmit,
  submitButtonText = "Submit",
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState<"next" | "prev">("next");
  const [responses, setResponses] = useState<Record<number, string | number>>(
    {}
  );
  const [error, setError] = useState<string | null>(null); // State for error messages

  const isQuestionAnswered = (question: Question) => {
    const answer = responses[question.id];
    if (question.type === "rating" || question.type === "mcq") {
      return answer !== undefined && answer !== "";
    } else if (question.type === "text") {
      //@ts-ignore
      return answer && answer.trim() !== "";
    }
    return false;
  };

  const handleNext = () => {
    setError(null); // Clear previous error
    if (currentIndex < questions.length - 1) {
      setDirection("next");
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setDirection("prev");
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleInputChange = (questionId: number, value: string | number) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: value,
    }));
  };

  const handleSubmit = () => {
    const unansweredQuestions = questions.filter(
      (q) => q.is_mandatory && !responses[q.id]
    );

    if (unansweredQuestions.length > 0) {
      setError("Please answer all mandatory questions before submitting.");
      return; // Stop submission if there are unanswered mandatory questions
    }

    const formattedResponses = questions.map((question) => {
      const response: FeedbackQuestion = {
        id: question.id,
        question_answer: question.question_answer,
        type: question.type,
      };

      // Add user response based on question type
      if (question.type === "rating") {
        response.rated = responses[question.id].toString() || 0; // Set default rating if not provided
      } else if (question.type === "mcq") {
        response.question_answer.selected =
          responses[question.id].toString() || ""; // Set selected option
      } else if (question.type === "text") {
        response.answer = responses[question.id].toString() || ""; // Set text answer
      }

      return response;
    });

    onSubmit({ feedback: formattedResponses });
  };

  const renderQuestion = (question: Question) => {
    switch (question.type) {
      case "rating":
        return (
          <div className="rating-input">
            {[
              { img: Disappointed, rating: 1, title: "Terrible" },
              { img: neutral, rating: 2, title: "Bad" },
              { img: slightly, rating: 3, title: "Okay" },
              { img: good, rating: 4, title: "Good" },
              { img: lovedIt, rating: 5, title: "Amazing" },
              { img: lovedIt, rating: 6, title: "Amazing" },
              { img: lovedIt, rating: 7, title: "Amazing" },
              { img: lovedIt, rating: 8, title: "Amazing" },
              { img: lovedIt, rating: 9, title: "Amazing" },
              { img: lovedIt, rating: 10, title: "Amazing" },
            ].map((rating) => (
              <label key={rating.rating}>
                <div className="rating-parent-container">
                  <div
                    className="rating-img-container"
                    style={{
                      background:
                        responses[question.id] === rating.rating
                          ? `linear-gradient(to right,rgba(44, 106, 177, 1),rgba(44, 106, 177, 0.5))`
                          : `linear-gradient(to right,rgba(200, 227, 255, 1), rgba(200, 227, 255, 0.5))`,
                      color:
                        responses[question.id] === rating.rating
                          ? "rgba(200, 227, 255, 1)"
                          : "rgba(44, 106, 177, 1)",
                    }}
                    onClick={() => {
                      handleInputChange(question.id, rating.rating);
                    }}
                  >
                    {rating.rating}
                  </div>
                </div>
              </label>
            ))}
          </div>
        );
      case "mcq":
        return (
          <div className="mcq-input">
            {question.question_answer.options &&
              Object.entries(question.question_answer.options).map(
                ([key, option]) => (
                  <label
                    key={key}
                    style={{
                      flexDirection: "row",
                      fontSize: 25,
                      alignItems: "center",
                      justifyContent: "center",
                      lineHeight: 1,
                      color: "rgba(44, 106, 177, 1)",
                    }}
                  >
                    <input
                      type="radio"
                      value={key}
                      checked={responses[question.id] === key}
                      onChange={() => handleInputChange(question.id, key)}
                      style={{ height: 20, width: 20, marginRight: 10 }}
                    />
                    {option}
                  </label>
                )
              )}
          </div>
        );
      case "text":
        const maxChars = 500; // Set your character limit here
        const currentText = responses[question.id] || ""; // Get current text or empty string
        return (
          <div>
            <textarea
              value={responses[question.id] || ""}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
              maxLength={maxChars} // Set the character limit
              rows={4} // Set height of textarea as needed
              style={{ width: "100%", maxHeight: "200px", minHeight: "100px" }} // Full width or adjust as needed
            />
            <div style={{ fontSize: "12px", color: "gray" }}>
              {maxChars - currentText.toString().length} characters left
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="carousel-container">
      <div className={`carousel-slide ${direction}`}>
        <h5 style={{ color: "rgba(44, 106, 177, 1)" }}>
          {" "}
          {questions[currentIndex].question_answer.question}
        </h5>
        {renderQuestion(questions[currentIndex])}
      </div>
      <div className="carousel-navigation">
        {currentIndex > 0 && (
          <button className="carousel-button prev" onClick={handlePrev}>
            &#10094; Previous
          </button>
        )}
        {currentIndex < questions.length - 1 ? (
          <button
            disabled={!isQuestionAnswered(questions[currentIndex])}
            className="carousel-button next"
            onClick={handleNext}
          >
            Next &#10095;
          </button>
        ) : (
          <button
            disabled={!isQuestionAnswered(questions[currentIndex])}
            className="carousel-button submit"
            onClick={handleSubmit}
          >
            {submitButtonText}
          </button>
        )}
      </div>
      {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}
    </div>
  );
};

export default Carousel;
