import React from "react";
import "./MobileDashboardHeader.css";
import { NFO, userProfileVector } from "../../../../Assets/images";
import { useNavigate } from "react-router";

interface dashboardHeaderProps {
  cbHandleOpenUserInfo: () => void;
}

const MobileDashboardHeader = (props: dashboardHeaderProps) => {
  const navigate = useNavigate();
  return (
    <header className="mobile-dashboard-header">
      <img
        className="mobile-dashboard-header-NFO-logo"
        alt="Nfo logo"
        src={NFO}
      />
      <button
        className="mobile-dashboard-header-user-profile"
        onClick={props.cbHandleOpenUserInfo}
      >
        <img src={userProfileVector} alt="O" />
      </button>
    </header>
  );
};

export default MobileDashboardHeader;
