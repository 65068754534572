import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

import {
  clock,
  arrowLeftWhite,
  BundleBanner,
  BundleBannerMobile,
} from "../../Assets/images";
import { mailIcon } from "../../Assets/svgs";
import { Alert, CircularProgress } from "@mui/material";
import { AuthContext } from "../../App";
import DashboardHeader from "./DashboardComponents/DashboardHeader/DashboardHeader";
import Bookshelf from "./DashboardComponents/Bookshelf/Bookshelf";
import NFOSessionCard from "./DashboardComponents/NFOSessionCard/NFOSessionCard";
import MockQuizCard from "./DashboardComponents/MockQuizCard/MockQuizCard";
import MobileDashboardHeader from "./DashboardComponents/MobileHeaderDashboard/MobileDashboardHeader";
import MobileUserInfoModal from "./DashboardComponents/MobileUserInfoModal/MobileUserInfoModal";
import MobileMockQuizCard from "./DashboardComponents/MockQuizCard/MobileMockQuizCard";
import ReferralCard from "./DashboardComponents/ReferralCard/ReferralCard";
import Modal from "../../Components/modal/Modal";
import ReferralModal from "./DashboardComponents/ReferralCard/ReferralModal";
import "./Dashboard.css";
import "./DashboardComponents/DashboardComponentsStyles.css";
import { setUserId, setUserProperties } from "firebase/analytics";
import { fAnalytics } from "../../Utils/firebase/firebaseConfig";
import UserDetails from "./DashboardComponents/UserDetails/UserDetails";
import {
  getStudentReferrals,
  logEvent,
  LogEventBody,
} from "../../Utils/api/auth";

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    auth: {
      student_username,
      name,
      grade,
      school,
      paid_for_mock_olympiad,
      showMockOlympiad,
      showLiveSession,
      showPracticeTestAndQuestionBankBanner,
      referralActive,
    },
  } = useContext(AuthContext);
  const urlParams = new URLSearchParams(window.location.search);
  console.log({ student_username });
  const orderId = urlParams.get("order_id");
  console.log("order_id param = ", orderId);
  const page = urlParams.get("page");
  console.log("page param = ", page);
  const [purchasedItems, setPurchasedItems] = useState(false);
  const [syncingPurchase, setSyncingPurchase] = useState(true);
  const token = localStorage.getItem("accessToken");
  const [bannerShown, setBannerShown] = useState(false);
  const { param } = useParams<{ param: string }>();
  console.log("param = ", param);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mobileUserInfoVisible, setMobileUserInfoVisible] = useState(false);
  // const [referredStudents, setReferredStudents] = useState<any>(['person 1', 'person 2', 'person 3']);
  const [referredStudents, setReferredStudents] = useState<any>([]);
  const [referralToggle, setReferralToggle] = useState(false);
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);

  const checkIfBannerShowed = () => {
    const bannerShowed = localStorage.getItem("bannerShowed");
    const bannerValue = bannerShowed && JSON.parse(bannerShowed);
    if (bannerValue) {
    } else {
      setBannerShown(true);
    }
  };

  if (student_username) {
    setUserId(fAnalytics, student_username);
    setUserProperties(fAnalytics, {
      userId: student_username,
      grade: grade,
      school: school,
    });
  }
  const handleCloseMobileUserInfo = () => {
    setMobileUserInfoVisible(false);
  };

  const handleShowMobileUserInfo = () => {
    setMobileUserInfoVisible(true);
  };

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  const getReferredStudents = async () => {
    try {
      const response = await getStudentReferrals();
      setReferredStudents(response.data.data);
    } catch (error) {
      console.error("Error fetching referred students", error);
    }
  };

  const copyReferral = async () => {
    const logBody: LogEventBody = {
      studentUserID: student_username,
      eventName: "clicked_referral_link",
      productIDs: [],
    };
    logEvent(logBody);

    const referralLink = `Hi!\n\nI'm excited to share that I'm participating in India's largest financial literacy\ncompetition—National Finance Olympiad 2024! 🎉\n\nThis Olympiad is a unique opportunity for students like us to gain essential financial\nskills, from investing, financial planning to understanding banking and more. It’s a\nchance to learn, grow, and prepare for a financially smart future!\n\nI’d love for you to join me in this journey! Compete with me and let’s see\nwho can master the world of finance. 💸\n\nClick here to learn more and register now: https://nationalfinanceolympiad.com/nfo-mobile?referredBy=${student_username}`;

    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        setReferralToggle(true);
        setTimeout(() => {
          setReferralToggle(false);
        }, 10000);
      })
      .catch((err) => {
        console.error("Failed to copy the text to clipboard: ", err);
      });
  };

  useEffect(() => {
    checkIfBannerShowed();
    getReferredStudents();
  }, []);

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="portal">
      <MobileDashboardHeader cbHandleOpenUserInfo={handleShowMobileUserInfo} />
      <div className="contentwrap">
        <DashboardHeader />
        <div className="contentwrap1">
          <UserDetails
            copyReferral={() => {
              setIsReferralModalOpen(true);
            }}
          />
          <div className="rightpanel">
            {showPracticeTestAndQuestionBankBanner &&
              !paid_for_mock_olympiad && (
                <div
                  onClick={() => {
                    openInSameTab(
                      `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=31&token=${token}&userName=${student_username}`
                    );
                  }}
                >
                  <img
                    src={BundleBannerMobile}
                    className="bundle-banner mobile"
                  />
                  <img src={BundleBanner} className="bundle-banner desktop" />
                </div>
              )}

            <div className="pagetitle">
              <b className="nfo-content">NFO Study Material</b>
              {/* <img className="line" alt="" src={TitleLine} /> */}
              <div className="line"></div>
            </div>

            <Bookshelf />

            <div className="nfo-portal-v2-bottom-cards">
              {showLiveSession && !(grade > 8 && grade < 11) && (
                <NFOSessionCard />
              )}

              {showMockOlympiad && (
                <>
                  <MockQuizCard
                    cbHandleMockClick={() => {
                      const token = localStorage.getItem("accessToken");
                      openInSameTab(
                        `https://olympiad.nationalfinanceolympiad.com?token=${token}&userName=${student_username}`
                      );
                    }}
                  />
                  <MobileMockQuizCard
                    cbHandleMockClick={() => {
                      const token = localStorage.getItem("accessToken");
                      openInSameTab(
                        `https://olympiad.nationalfinanceolympiad.com?token=${token}&userName=${student_username}`
                      );
                    }}
                  />
                </>
              )}
              {referralActive && (
                <ReferralCard
                  toggle={referralToggle}
                  setIsReferralModalOpen={setIsReferralModalOpen}
                  referredCnt={referredStudents.length}
                  copyReferral={copyReferral}
                />
              )}
            </div>

            <div className="mobile-bottom-buttons">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <button className="mobile-supportbutton">
                  <img className="mobile-vector-icon" alt="" src={mailIcon} />

                  <a
                    className="mobile-support-text"
                    href="mailto:support@nationalfinanceolympiad.com"
                  >
                    Contact NFO Support
                  </a>
                </button>
                <button
                  className="mobile-supportbutton"
                  onClick={() => {
                    navigate("/orderHistory");
                  }}
                >
                  <img className="mobile-vector-icon" src={clock} alt="O" />
                  <div className="mobile-support-text">Purchase History</div>
                </button>
              </div>
              <div>
                <button
                  className="mobile-signout-button"
                  onClick={() => {
                    Cookies.remove("accessToken");
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                  }}
                >
                  Signout of NFO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {error.length > 0 && (
        <Alert
          style={{ position: "absolute", top: 10, left: 10 }}
          severity="error"
        >
          {error}
        </Alert>
      )}
      <MobileUserInfoModal
        isVisible={mobileUserInfoVisible}
        onClose={() => {
          handleCloseMobileUserInfo();
        }}
      >
        <div className="mobile-userInfoWrap">
          <button
            className="mobile-userProfileBackButton"
            onClick={handleCloseMobileUserInfo}
          >
            <img src={arrowLeftWhite} alt="&lt;-" />
            &nbsp;Go back
          </button>
          <br />
          <div className="mobile-studentid">
            <b className="mobile-name">{name}</b>
            <div className="line" />
            <div className="mobile-grade">{grade}th Grade</div>
            <div className="line" />
            <div className="mobile-grade">
              <div className="mobile-school">{school} </div>
            </div>
            <div className="line" />
            <div className="mobile-grade">
              <div className="mobile-school">Roll No.- {student_username} </div>
            </div>
          </div>
        </div>
      </MobileUserInfoModal>
      <Modal
        isOpen={isReferralModalOpen}
        closable={true}
        onClose={() => setIsReferralModalOpen(false)}
      >
        <ReferralModal
          toggle={referralToggle}
          referredStudents={referredStudents}
          copyReferral={copyReferral}
        />
      </Modal>

      <Modal
        isOpen={bannerShown}
        onClose={() => {
          setBannerShown(false);
          localStorage.setItem("bannerShowed", JSON.stringify(true));
        }}
        closable={true}
      >
        <div style={{ cursor: "pointer" }}>
          <img
            onClick={() => {
              localStorage.setItem("bannerShowed", JSON.stringify(true));
              setBannerShown(false);
              setIsReferralModalOpen(true);
            }}
            src={require("../../Assets/images/BannerImage.png")}
            style={{
              height: "100%",
              width: "100%",
              cursor: "pointer",
              borderRadius: 10,
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Dashboard;
