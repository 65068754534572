import React from "react";
import "./MobileUserInfoModal.css";
import { Modal } from "@mui/material";

interface InfoModalInterface {
  children: React.ReactElement;
  isVisible: boolean;
  onClose: () => void;
}

const MobileUserInfoModal = (props: InfoModalInterface) => {
  return (
    <Modal
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
      open={props.isVisible}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {props.children}
    </Modal>
  );
};

export default MobileUserInfoModal;
